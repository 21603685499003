import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzPaginationModule } from 'ng-zorro-antd/pagination';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { BaseComponentsModule } from 'projects/base-components/src/public-api';
import { AddPromocodeComponent } from './add-promocode/add-promocode.component';
import { ChooseAccountSizeComponent } from './choose-account-size/choose-account-size.component';
import { ChooseTradingPlatformComponent } from './choose-trading-platform/choose-trading-platform.component';
import { ConfirmSubscriptionInfoComponent } from './confirm-subscription-info/confirm-subscription-info.component';
import { ConfirmSubscriptionComponent } from './confirm-subscription/confirm-subscription.component';
import { CreateSubscriptionComponent } from './create-subscription.component';
import { InfoGoToProComponent } from './info-go-to-pro/info-go-to-pro.component';
import { NavComponent } from './nav/nav.component';
import { PaymentCreditCardComponent } from './payment-credit-card/payment-credit-card.component';
import { StepComponent } from './step/step.component';
import { SuccessfullyComponent } from './successfully/successfully.component';
import { RouterModule } from '@angular/router';
import { ConfirmWalletTransactionComponent } from './confirm-wallet-transaction/confirm-wallet-transaction.component';
import { CommonPopUpModule } from 'common-pop-up';
import { LoaderModule } from 'loader';
import { PaymentMethodComponent } from './payment-method/payment-method.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { ModalWindowModule } from 'modal-window';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Translate } from 'localization';
import { PaymentCreditCardNmiComponent } from './payment-credit-card-nmi/payment-credit-card-nmi.component';
import { ConfirmCreditsTransactionComponent } from './confirm-credits-transaction/confirm-credits-transaction.component';

@NgModule({
  declarations: [
    CreateSubscriptionComponent,
    ChooseAccountSizeComponent,
    ChooseTradingPlatformComponent,
    ConfirmSubscriptionComponent,
    ConfirmSubscriptionInfoComponent,
    AddPromocodeComponent,
    NavComponent,
    PaymentCreditCardComponent,
    StepComponent,
    SuccessfullyComponent,
    InfoGoToProComponent,
    ConfirmWalletTransactionComponent,
    PaymentMethodComponent,
    PaymentCreditCardNmiComponent,
    ConfirmCreditsTransactionComponent,
  ],
  imports: [
    CommonModule,
    NzAvatarModule,
    NzFormModule,
    NzPaginationModule,
    NzTabsModule,
    CommonPopUpModule,
    NzMessageModule,
    LoaderModule,
    FormsModule,
    ReactiveFormsModule,
    BaseComponentsModule,
    RouterModule,
    SlickCarouselModule,
    ModalWindowModule,
    NzInputModule,
    Translate,
  ],
  exports: [
    CreateSubscriptionComponent,
    PaymentCreditCardComponent,
    SuccessfullyComponent,
    NavComponent,
    PaymentMethodComponent,
  ],
})
export class CreateSubscriptionModule {}
