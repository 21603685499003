import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
} from '@angular/core';
import { ErrorCode, SuccessfulMessage } from '../enum';
import { AgreementsService } from '../services/agreements.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ISuccessfulInfo } from '../interfaces';
import { AccountType, ErrorHttpService } from 'repository';

@Component({
  selector: 'lib-successfully',
  templateUrl: './successfully.component.html',
  styleUrls: ['./successfully.component.scss'],
})
@UntilDestroy()
export class SuccessfullyComponent implements OnChanges, OnDestroy {
  @Input()
  public type: SuccessfulMessage;

  @Input()
  public accountType: number;

  @Input()
  public resetAmount: number;

  @Output()
  public readonly loading = new EventEmitter<boolean>();

  @Output()
  public readonly isClose = new EventEmitter<boolean>();

  public urlForSign?: string;

  public readonly info: ISuccessfulInfo = {
    payByPayPal: {
      image: '',
      title: 'successfully-title-1',
      description: 'successfully-description-1',
      okButtonText: 'Ok',
    },
    depositByPayPal: {
      title: 'successfully-title-1',
      description: 'successfully-description-2',

      okButtonText: 'Ok',
    },
    resetAccount: {
      image: 'success-reset',
      title: 'successfully-title-3',
      description: 'successfully-description-3',
      okButtonText: 'OK',
    },
    resetProAccount: {
      image: 'success-reset',
      title: 'successfully-title-4',
      okButtonText: 'OK',
    },
    createNewSubscription: {
      image: 'logos/mail',
      title: 'successfully-title-3',
      description: 'successfully-description-4',
      okButtonText: 'OK',
    },
    agreementPayment: {
      image: 'success-reset',
      title: 'successfully-title-5',
      description: 'successfully-description-5',
      linkText: 'SIGN',
    },
    deposit: {
      image: 'error',
      title: 'successfully-title-6',
      description: 'successfully-description-6',
      cancelButtonText: 'change payment method',
      okButtonText: 'try again',
    },
    errorDoublePayment: {
      image: 'error',
      title: 'successfully-title-7',
      description: 'successfully-description-7',
      okButtonText: 'OK',
    },
    changePayment: {
      image: 'logos/mail',
      title: 'successfully-title-3',
      description: 'successfully-description-8',
      okButtonText: 'OK',
    },
    depositIsPaid: {
      image: 'success-reset',
      title: 'successfully-title-9',
      okButtonText: 'Ok',
    },
  };

  public readonly SuccessfulMessage = SuccessfulMessage;
  public readonly AccountType = AccountType;

  private agreementInterval: ReturnType<typeof setInterval>;

  constructor(
    private readonly _agreementsService: AgreementsService,
    private readonly _errorHttpService: ErrorHttpService
  ) {}

  public ngOnChanges(): void {
    switch (this.type) {
      case SuccessfulMessage.DEPOSIT_IS_PAID:
        this.loading.emit(false);

        return;
      case SuccessfulMessage.AGREEMENT_PAYMENT:
        this.agreementInterval = setInterval(() => {
          if (!this.urlForSign) {
            this.signContract();
          }
        }, 3000);

        break;
      default:
        break;
    }
  }

  public ngOnDestroy(): void {
    clearInterval(this.agreementInterval);
  }

  public close(): void {
    this.isClose.emit(true);
  }

  private signContract(): void {
    this._agreementsService
      .sign()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          const proAgreementLink = res.proAgreementLink;
          const poaAgreementLink = res.poaAgreementLink;
          if (proAgreementLink || poaAgreementLink) {
            this.urlForSign = proAgreementLink;
            this.loading.emit(false);
            clearInterval(this.agreementInterval);
          }
        },
        (error) => {
          this.loading.emit(false);
          if (+error?.error?.errorCode !== ErrorCode.WAIT_SING_RESPONSE) {
            this._errorHttpService.showMessage(error);
          }
        }
      );
  }
}
