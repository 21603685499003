import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/internal/Observable';
import {
  IChangePaymentMethod,
  IChangePaymentMethodBody,
  ICurrentPaymentService,
} from '../../interfaces';
import { IUserCreditsList } from 'repository';

@Injectable({
  providedIn: 'root',
})
export class PaymentsService {
  constructor(private readonly _http: HttpClient) {}

  changePaymentMethod(
    body: IChangePaymentMethodBody
  ): Observable<IChangePaymentMethod> {
    return this._http.post<IChangePaymentMethod>(
      `${environment.paymentsUrl}/api/PaymentServices/change`,
      body
    );
  }

  public getCurrentPaymentMethod(
    accountId: number
  ): Observable<ICurrentPaymentService> {
    return this._http.get<ICurrentPaymentService>(
      `${environment.paymentsUrl}/api/PaymentServices/${accountId}`
    );
  }

  getYourCredits(): Observable<IUserCreditsList> {
    return this._http.get<IUserCreditsList>(
      environment.paymentsUrl + '/api/Credits/user-credits'
    );
  }

  public getGateway(
    paymentGateways: number
  ): Observable<{ userName: string; password: string }> {
    return this._http.get<{
      userName: string;
      password: string;
    }>(
      `${environment.paymentsUrl}/api/SpreedlyPayments/payment-gateway?gateway=${paymentGateways}`
    );
  }

  public getSpreedlyArmGateway(
    username: string,
    password: string,
    amount: number
    // tslint:disable-next-line:no-any
  ): Observable<any> {
    const authorizationData = 'Basic ' + btoa(username + ':' + password);

    const headerOptions = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: authorizationData,
    });

    return (
      this._http
        // tslint:disable-next-line:no-any
        .post<any>(
          `${environment.spreedlyUrl}/${environment.stripe_arm_gateway}/purchase.json`,
          {
            transaction: {
              amount,
              currency_code: 'USD',
              redirect_url: 'https://dev.takeprofittrader.org/edirect',
              callback_url: 'https://dev.takeprofittrader.org/',
              payment_method: {
                payment_method_type: 'stripe_apm',
                apm_types: ['us_bank_account'],
              },
            },
          },
          { headers: headerOptions }
        )
    );
  }
}
