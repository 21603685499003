import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { AccountSizeService } from '@app/services/account-size.service';
import { User } from 'user';
import marketType from '/src/data/market-type.json';
import {
  ActionType,
  SubscriptionSize,
  SubscriptionSizePrice,
  TypeOfCreateSubscription,
} from '../enum';
import {
  AccountType,
  IAccountSize,
  IMarketType,
  LocalStorage,
  PaymentMethod,
} from 'repository';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITradingData } from '../interfaces';
import { ActivatedRoute } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'lib-confirm-subscription',
  templateUrl: './confirm-subscription.component.html',
  styleUrls: ['./confirm-subscription.component.scss'],
})
export class ConfirmSubscriptionComponent implements OnInit, OnChanges {
  @Input()
  public accountSize?: string;

  @Input()
  public update?: boolean;

  @Input() tradingData: ITradingData[] = [];

  @Input()
  public type = TypeOfCreateSubscription.CREATE_SUBSCRIPTION;

  @Input()
  public monthlyPrice: number;

  @Input()
  public accountType: number;

  @Input()
  public accountId: number;

  @Input()
  public isConfirmSubscription = false;

  @Output()
  public readonly paymentMethod = new EventEmitter<string>();

  @Output()
  public readonly toPayment = new EventEmitter<void>();

  @Output()
  public readonly promocodeIdChange = new EventEmitter<number>();

  @Output()
  public readonly priceChange = new EventEmitter<number>();

  actionType: ActionType;

  accountSizes: IAccountSize[] = [];
  marketTypes: IMarketType[] = marketType;
  size: IAccountSize;
  tradingPlatform: string;
  name: string;
  textPrice: string;
  price: number;
  promoPrice: number;
  currentService = PaymentMethod.CREDIT_CARD;

  public readonly PaymentMethod = PaymentMethod;
  public readonly TypeOfCreateSubscription = TypeOfCreateSubscription;

  constructor(
    private readonly _user: User,
    private readonly _accountSizeService: AccountSizeService,
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _localStorage: LocalStorage
  ) {}

  ngOnInit(): void {
    this.getSubscriptionsList();
  }

  submit(): boolean {
    return true;
  }

  ngOnChanges(): void {
    let tradingPlatform: number;
    if (this._activatedRoute.snapshot.queryParams?.typePlatform) {
      tradingPlatform = this._activatedRoute.snapshot.queryParams?.typePlatform;
    } else {
      tradingPlatform = +this._localStorage.getItem('platformType');
    }

    this.tradingPlatform =
      +tradingPlatform === 1 ? 'CQG Data Feed' : 'Rithmic Data Feed';

    this.name = this._user.fullName;

    const account: IAccountSize | { accountName?: string } = JSON.parse(
      this._localStorage.getItem('accountSize')
    );
    if ((account as { accountName?: string })?.accountName) {
      this.accountSize = (account as { accountName?: string }).accountName;
    } else {
      this.accountSize = `${(account as IAccountSize).name} ${
        this.marketTypes[(account as IAccountSize)?.marketType]?.type
      }`;
    }

    switch (this.type) {
      case TypeOfCreateSubscription.CREATE_SUBSCRIPTION:
        this.actionType = ActionType.NEW_USER;

        const price = (account as IAccountSize)?.price;

        this.price = price;
        this.textPrice = `$${price} / month`;

        break;
      case TypeOfCreateSubscription.RESET_ACCOUNT:
        this.actionType = ActionType.RESET_ACCOUNT;

        const accountSize1: IAccountSize = JSON.parse(
          this._localStorage.getItem('accountSize')
        ) as IAccountSize;
        this.price = 100;
        /*       const currentBalanceTest =
            +localStorage.getItem('platformType') === 1
              ? this.tradingData[0].amount
              : this.tradingData[0]?.acctBal;
          /!*        switch (accountSize1?.id) {
            case SubscriptionSize.$25K:
              this.price = this.getPriceCost(
                currentBalanceTest,
                SubscriptionSizePrice.$25K,
                SubscriptionSizeNumber.$25K
              );
              break;
            case SubscriptionSize.$50K:
              this.price = this.getPriceCost(
                currentBalanceTest,
                SubscriptionSizePrice.$50K,
                SubscriptionSizeNumber.$50K
              );
              break;
            case SubscriptionSize.$75K:
              this.price = this.getPriceCost(
                currentBalanceTest,
                SubscriptionSizePrice.$75K,
                SubscriptionSizeNumber.$75K
              );
              break;
            case SubscriptionSize.$100K:
              this.price = this.getPriceCost(
                currentBalanceTest,
                SubscriptionSizePrice.$100K,
                SubscriptionSizeNumber.$100K
              );
              break;
            case SubscriptionSize.$150K:
              this.price = this.getPriceCost(
                currentBalanceTest,
                SubscriptionSizePrice.$150K,
                SubscriptionSizeNumber.$150K
              );
              break;
            default:
              break;
          }*!/*/
        this.textPrice = `$${this.price}`;
        this.priceChange.emit(this.price);

        break;
      case TypeOfCreateSubscription.RESET_PRO_ACCOUNT:
        this.actionType = ActionType.PRO_RESET_ACCOUNT;

        const accountSize: IAccountSize = JSON.parse(
          this._localStorage.getItem('accountSize')
        );

        const currentBalance =
          +tradingPlatform === 1
            ? this.tradingData[0]?.amount
            : this.tradingData[0]?.acctBal;

        this.price = 100;
        if (
          (this.accountType === AccountType.PRO ||
            this.accountType === AccountType.PRO_PLUS) &&
          this.tradingData
        ) {
          switch (accountSize?.id) {
            case SubscriptionSize.$25K:
            case SubscriptionSize.$25K_PLUS:
              this.price = SubscriptionSizePrice.$25K;
              break;
            case SubscriptionSize.$50K:
            case SubscriptionSize.$50K_PLUS:
              this.price = SubscriptionSizePrice.$50K;
              break;

            case SubscriptionSize.$75K:
            case SubscriptionSize.$75K_PLUS:
              this.price = SubscriptionSizePrice.$75K;
              break;
            case SubscriptionSize.$100K:
            case SubscriptionSize.$100K_PLUS:
              this.price = SubscriptionSizePrice.$100K;
              break;
            case SubscriptionSize.$150K:
            case SubscriptionSize.$150K_PLUS:
              this.price = SubscriptionSizePrice.$150K;
              break;
            default:
              break;
          }
        }

        this.textPrice = `$${this.price}`;
        this.priceChange.emit(this.price);

        break;
      case TypeOfCreateSubscription.CHANGE_PAYMENT:
        this.actionType = ActionType.NEW_USER;

        this.textPrice = `$${this.monthlyPrice} / month`;

        this.price = this.monthlyPrice;
        break;
      case TypeOfCreateSubscription.AGREEMENT_PAYMENT:
        this.price = 130;
        this.textPrice = `$${this.price}`;
        break;
      default:
        this.actionType = ActionType.NEW_USER;

        break;
    }
  }

  public getPromoPrice(promoPrice: number): void {
    this.promoPrice = promoPrice;
  }

  emitPromocode(value: number): void {
    this.promocodeIdChange.emit(value);
  }

  public emitToPayment(): void {
    this.toPayment.emit();
  }

  public emitPaymentMethod(paymentMethod: PaymentMethod): void {
    this.paymentMethod.emit(paymentMethod);
  }

  private getSubscriptionsList(): void {
    this._accountSizeService
      .getSubscriptionList()
      .pipe(untilDestroyed(this))
      .subscribe(
        (res) => {
          this.accountSizes = res.items;
        },
        (error) => console.error(error)
      );
  }

  private getPriceCost(
    currentBalance: number,
    priseSize: number,
    priceNumber: number
  ): number {
    return currentBalance >= priceNumber - priseSize
      ? +priseSize.toFixed(2)
      : +(priceNumber - currentBalance).toFixed(2);
  }

  private getPriceCostProPlus(
    currentBalance: number, // -2692.04
    priseSize: number, // 1499
    priceNumber: number // 0
  ): number {
    const surcharge = 130;

    return +currentBalance >= +priceNumber - +priseSize
      ? +(+priseSize + surcharge).toFixed(2)
      : +(surcharge + currentBalance * -1).toFixed(2);
  }
}
