<lib-modal-window [isVisible]="show" (isClose)="close()">
  <div class="content">
    <div class="primary-flex-center logo-box">
      <lib-takeprofit-title [desktopOnly]="true"></lib-takeprofit-title>
    </div>

    <div>
      <h2 class="welcome text">{{ 'welcome-back' | translate }}</h2>
      <div class="primary-flex-start">
        <p class="info text">{{ 'don_t-have-an-aaccount' | translate }}</p>
        <button
          type="button"
          class="primary-invisible text info-btn main-color"
          (click)="signUp()"
        >
          {{ 'sign-up' | translate }}
        </button>
      </div>
    </div>

    <div class="login-actions">
      <lib-form-for-login
        [isGoToDashboard]="isGoToDashboard"
        (isLogin)="emitLogin($event)"
        (isForgotPassword)="toggleForgotPassword()"
      ></lib-form-for-login>
    </div>

    <div class="primary-flex-center">
      <p class="info text">{{ 'did-not-receive' | translate }}</p>
      <button
        type="button"
        class="primary-invisible text info-btn main-color"
        (click)="showResendConfirmEmail()"
      >
        {{ 'resend' | translate }}
      </button>
    </div>
  </div>
</lib-modal-window>

<lib-login-forgot-password
  [(show)]="showForgotPassword"
  (showInfoSendMail)="closeReset()"
></lib-login-forgot-password>

<lib-login-info-send-mail
  [loginForgotPassComponent]="loginForgotPassComponent"
  [(show)]="showLoginInfoSendmail"
></lib-login-info-send-mail>

<lib-resend-confirm-email
  [(isShow)]="isShowResendConfirmEmail"
  [isExpired]="isExpiredConfirmEmail"
></lib-resend-confirm-email>
