<div class="d-payment-via">
  <h3 class="payment-via">{{ 'complete-payment-via' | translate }}:</h3>
  <div
    class="primary-space-between payment"
    *ngIf="isShowItem.stripe"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.CREDIT_CARD,
      'payment-checkout': payment === PaymentMethod.CREDIT_CARD
    }"
    (click)="choosePayment(PaymentMethod.CREDIT_CARD)"
    (dblclick)="goToPayment(PaymentMethod.CREDIT_CARD)"
  >
    <p>{{ 'bank-credit-card' | translate }}</p>
    <div>
      <img
        class="logo"
        src="/assets/logos/{{ creditCardImg.src }}.png"
        [alt]="creditCardImg.alt"
        *ngFor="let creditCardImg of creditCardImgList"
      />
    </div>
  </div>
  <div
    class="primary-space-between payment"
    *ngIf="isShowItem.nmi"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.CREDIT_CARD_NMI,
      'payment-checkout': payment === PaymentMethod.CREDIT_CARD_NMI
    }"
    (click)="choosePayment(PaymentMethod.CREDIT_CARD_NMI)"
    (dblclick)="goToPayment(PaymentMethod.CREDIT_CARD_NMI)"
  >
    <p>{{ 'bank-credit-card' | translate }} NMI</p>
    <div>
      <img
        class="logo"
        src="/assets/logos/{{ creditCardImg.src }}.png"
        [alt]="creditCardImg.alt"
        *ngFor="let creditCardImg of creditCardImgList"
      />
    </div>
  </div>

  <div
    class="payment primary-space-between"
    *ngIf="isShowItem.paypal"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.PAY_PAL,
      'payment-checkout': payment === PaymentMethod.PAY_PAL
    }"
    (click)="choosePayment(PaymentMethod.PAY_PAL)"
    (dblclick)="goToPayment(PaymentMethod.PAY_PAL)"
  >
    <p>{{ 'PayPal' | translate }}</p>
    <img class="logo" src="/assets/logos/paypal.png" alt="paypal" />
  </div>
  <div
    class="payment primary-space-between"
    *ngIf="isShowItem.deel"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.DEEL,
      'payment-checkout': payment === PaymentMethod.DEEL
    }"
    (click)="choosePayment(PaymentMethod.DEEL)"
    (dblclick)="goToPayment(PaymentMethod.DEEL)"
  >
    <p>{{ 'ach' | translate }}</p>
  </div>
  <div
    class="payment primary-space-between"
    *ngIf="isShowItem.wallet"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.WALLET,
      'payment-checkout': payment === PaymentMethod.WALLET,
      'disable-wallet': !showWallet
    }"
    (click)="showWallet ? choosePayment(PaymentMethod.WALLET) : null"
    (dblclick)="showWallet ? choosePayment(PaymentMethod.WALLET) : null"
  >
    <p>{{ 'wallet' | translate }}</p>
    <div>
      <p>${{ walletBalance | number: '0.2-2' }}</p>
      <img class="logo" src="/assets/logos/wallet.svg" alt="wallet" />
    </div>
  </div>

  <div
    class="primary-space-between payment"
    *ngIf="isUseCredits"
    [ngClass]="{
      'current-service': currentService === PaymentMethod.USER_CREDITS,
      'payment-checkout': payment === PaymentMethod.USER_CREDITS
    }"
    (click)="choosePayment(PaymentMethod.USER_CREDITS)"
    (dblclick)="goToPayment(PaymentMethod.USER_CREDITS)"
  >
    <p>{{ 'your-credits' | translate }}</p>
    <div>
      <p>{{ useCredits }}</p>
      <img class="logo" [src]="creditsIcon" alt="your-credits" />
    </div>
  </div>

  <p
    class="warning"
    *ngIf="
      type === TypeOfCreateSubscription.CREATE_SUBSCRIPTION && idAccount != 12
    "
  >
    {{ 'you-will-be-billed' | translate }}
  </p>
</div>
