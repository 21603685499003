import { AfterViewInit, Component, Input } from '@angular/core';
import { WalletInfoService } from 'projects/wallet-info/src/lib/wallet-info.service';
import {
  ErrorHttpService,
  IEventList,
  IWithdrawRequestList,
  WithdrawRequestStatus,
} from 'repository';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, switchMap } from 'rxjs/operators';
import { CalendarService } from '../../../../calendar/src/lib/service/calendar.service';
import * as moment from 'moment';
import { User } from 'user';

@Component({
  selector: 'lib-notification',
  templateUrl: './wallet-notification.component.html',
  styleUrls: ['./wallet-notification.component.scss'],
})
export class WalletNotificationComponent implements AfterViewInit {
  @Input() small = false;
  @Input() toWallet = false;
  @Input() toCalendar = false;
  @Input() notification = 0;

  $triggerWallet = new Subject();
  $triggerCalendar = new Subject();
  WithdrawRequestStatus = WithdrawRequestStatus;

  walletInfo$: Observable<IWithdrawRequestList[]> = this.$triggerWallet
    .pipe(
      switchMap(() => this._walletInfoService.getWithdrawRequest(this.user.id))
    )
    .pipe(
      filter(
        (item, index) => item[index].status === WithdrawRequestStatus.InProgress
      )
    );

  public get startDate(): string {
    return moment(new Date(), 'YYYY-MM-DDT00:00:00').toISOString();
  }

  calendarEvent$: Observable<IEventList[]> = this.$triggerCalendar.pipe(
    debounceTime(1000),
    switchMap(() =>
      this._calendarService.getEventByRange({
        startDate: this.startDate,
        endDate: new Date().toISOString(),
      })
    )
  );

  constructor(
    private readonly _errorHttpService: ErrorHttpService,
    private readonly _calendarService: CalendarService,
    private readonly user: User,
    private readonly _walletInfoService: WalletInfoService
  ) {}

  ngAfterViewInit(): void {
    if (this.toWallet || this.small) {
      this.$triggerWallet.next();
    }

    this.$triggerCalendar.next();
  }
}
