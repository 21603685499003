import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NotificationService } from './notification.service';
import errorList from 'src/data/error.json';
import { TypeMessage } from '../../enums';

export enum ErrorCodes {
  NO_INTERNET_CONNECTION = 504,
  NO_LOGIN = 401,
  ACCESS_DENIED = 403,
  NOT_FOUND = 404,
  SERVER_NOT_RESPONDING = 500,
  BAD_REQUEST = 400,
  NOT_ACCEPT_RULE = 40004,
}

@Injectable({
  providedIn: 'root',
})
export class ErrorHttpService {
  constructor(private readonly _notification: NotificationService) {}

  showMessage(error: HttpErrorResponse): void {
    const errorCode: string = error?.error?.errorCode;
    let errorMsg = 'N/a';

    switch (error.status) {
      case ErrorCodes.NOT_FOUND:
        errorMsg = 'You don`t have an account, please register.';
        break;
      case ErrorCodes.NO_LOGIN:
        errorMsg = 'Access denied. Please, log in.';
        break;
      case ErrorCodes.ACCESS_DENIED:
        errorMsg = 'Access denied. Please, log in.';
        break;
      case ErrorCodes.NO_INTERNET_CONNECTION:
        errorMsg = 'Check your internet connection!';
        break;
      case ErrorCodes.SERVER_NOT_RESPONDING:
        errorMsg = 'Server is not responding...';
        break;
      case ErrorCodes.NOT_ACCEPT_RULE:
        errorMsg = 'Accept the rules';
        break;
      default:
        break;
    }

    if (errorCode) {
      errorMsg = errorList[errorCode];
    }

    this._notification.showBlankNotification(errorMsg, TypeMessage.ERROR);
  }
}
