<div class="new-background">
  <header id="header" class="header">
    <nav class="navigation">
      <lib-logo></lib-logo>
      <ul class="list" *ngIf="!user.isLogined">
        <li *ngFor="let l of links" class="list-item">
          <span *ngIf="!l.target; else showDefaultLink">
            <a
              [routerLink]="l.link"
              class="list-link"
              [fragment]="l.fragment"
              >{{ l.text | translate }}</a
            >
          </span>
          <ng-template #showDefaultLink>
            <a [href]="l.link" target="_blank" class="list-link">{{
              l.text | translate
            }}</a>
          </ng-template>
        </li>
      </ul>
      <div class="login" *ngIf="!user.isLogined">
        <button class="login-link primary-btn" (click)="toggleLogin()">
          {{ 'login' | translate }}
        </button>
        <a
          class="login-started"
          [routerLink]="['/']"
          [queryParams]="queryParams"
          fragment="s-market"
          >{{ 'get-started' | translate }}</a
        >
        <lib-change-lang></lib-change-lang>
      </div>

      <button
        class="open-menu-btn primary-btn"
        (click)="modalService.open('mobile-menu-modal')"
      >
        <i class="fas fa-bars"></i>
      </button>
    </nav>
    <jw-modal id="mobile-menu-modal">
      <div class="mobile-menu-container">
        <div class="close-wrapper">
          <button
            type="button"
            class="close-menu-btn primary-btn"
            (click)="modalService.close('mobile-menu-modal')"
          >
            <i class="fas fa-times"></i>
          </button>
        </div>
        <div class="mobile">
          <ul class="mobile-list">
            <li *ngFor="let l of links" class="mobile-item">
              <span *ngIf="!l.target; else showDefaultLink">
                <a
                  [routerLink]="l?.link"
                  [fragment]="l?.fragment"
                  routerLinkActive="active-header-link"
                  class="mobile-link"
                  (click)="modalService.close('mobile-menu-modal')"
                  >{{ l?.text | translate }}</a
                >
              </span>
              <ng-template #showDefaultLink>
                <a
                  [href]="l.link"
                  target="_blank"
                  routerLinkActive="active-header-link"
                  class="mobile-link"
                  (click)="modalService.close('mobile-menu-modal')"
                  >{{ l.text | translate }}</a
                >
              </ng-template>
            </li>
          </ul>
        </div>
        <div class="mobile-btns-wrapper">
          <a class="mobile-login" (click)="toggleLogin()">{{
            'login' | translate
          }}</a>
          <a
            [routerLink]="['/']"
            [queryParams]="queryParams"
            fragment="s-market"
            class="mobile-started"
            (click)="
              modalService.close('mobile-menu-modal');
              backToSection('s-market', queryParams)
            "
            >{{ 'get-started' | translate }}</a
          >

          <lib-change-lang [menu]="true"></lib-change-lang>
        </div>
      </div>
    </jw-modal>
  </header>
</div>
